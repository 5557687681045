import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as state from "@/store/modules/app"
import vuetify from "./plugins/vuetify";
import VueCompositionAPI from "@vue/composition-api"

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI)

console.log("main2")
console.log("https://apileba.grandecaccia.it/api")

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
