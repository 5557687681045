












































































import { defineComponent, ref, watch } from '@vue/composition-api'


export default defineComponent({

  name: 'App',

  components: {},

  setup() {

    const drawer = ref(false)
    const group = ref(0)

    // watch(group, () => {
    //   // si è cliccato su una voce. Chiudo il menu
    //   drawer.value = false
    // })

    return {
      group,
      drawer,
    }

  }

})
