import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
// import * as state from "@/store/modules/app"

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/register",
    name: "Register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RegisterView.vue"),
    meta: {
      guest: true
    }
  },
  {
    path: "/players",
    name: "Players",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PlayersView.vue"),
    meta: {
      guest: true
    }
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   console.log("beforeEach: ", to.fullPath, from.fullPath)
//   // to.matched.some(record => {
//   //   console.log(record.meta)
//   // })
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//       // la pagian che si vuole visitare richiede l'autenticazione
//       console.log("beforeEach: la pagina richiede autenticazione")
//       // if (localStorage.getItem('jwt') == null) {
//       if (state.getters.isLogged === false) {
//           // non c'è salvato nessun token. Mando a login
//           next({
//               path: '/login',
//               query: { redirect: to.fullPath }
//           })
//       } else {
//           // il token c'è.
//           const item = localStorage.getItem('jwt')
//           if(item) {
//             next()
//             // const user = JSON.parse(item)
//             // if(to.matched.some(record => record.meta.is_admin)) {
                
//             //     if(user.is_admin == 1){
//             //         next()
//             //     }
//             //     else{
//             //         next({ name: 'userboard'})
//             //     }
//             // }else {
//             //     next()
//             // }
//           }

//       }
//   } else if(to.matched.some(record => record.meta.guest)) {
//     console.log("beforeEach: la pagina è per guest")
//     next()
//     // if(localStorage.getItem('jwt') == null){
//     //     next()
//     // }else{
//     //   next({
//     //     path: '/login',
//     //     query: { redirect: to.fullPath }
//     //   })
//     // }
//   }else {
//       next()
//   }
// })


export default router;
