import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        // dark: true,
        themes: {
          light: {
            primary: {
              base: '#FFD400',
              darken1: "#FFB900",
            },
            secondary: '#000000',
            accent: '#FFEA80',
            error: '#FF5252',
            info: '#2196F3',
            success: '#4CAF50',
            warning: '#FFC107',
          },
        },
      },
});