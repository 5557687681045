import { HTTP_LEADERBOARD } from '@/api/http-common'
import { Player } from '@/types/game'


export const backendAPI = {

  registerPlayer: (playerData: Player) => {
    console.log("BackendAPI: registerPlayer")
    return new Promise<string>((resolve, reject) => {
      HTTP_LEADERBOARD.post<string>("/players", playerData)
      .then(response => {
        console.log("arrived: ", response)
        if (response.status === 201) {
          resolve(response.data)
        }else{
          reject("Errore durante la registrazione")
        }
      })
      .catch(error => {
        console.log("error: ", error.response.data.detail)
        const errorMsg = error.response.data.detail || "Errore durante la registrazione"
        reject(errorMsg)
      })
    })
  },

  getPlayers: () => {
    console.log("BackendAPI: getPlayers")
    return new Promise<Player[]>((resolve, reject) => {
      HTTP_LEADERBOARD.get<Player[]>("/players")
      .then(response => {
        console.log("arrived: ", response)
        if (response.status === 200) {
          resolve(response.data)
        }else{
          reject("Errore durante la fetch dei giocatori")
        }
      })
      .catch(error => {
        console.log("error: ", error.response.data.detail)
        const errorMsg = error.response.data.detail || "Impossibile scaricare la lista dei giocatori"
        reject(errorMsg)
      })
    })
  },

}