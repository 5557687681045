import axios from "axios";


// export const HTTP = axios.create({
//   baseURL: "http://localhost:8080/",
//   headers: {
//     Authorization: "Bearer {token}"
//   }
// })


//   baseURL: "http://46.101.185.7:5000/",
//   baseURL: "http://localhost:8000/",


// export const HTTP_LOCKDOWN = axios.create({
//   baseURL: process.env.VUE_APP_LOCKDOWN_BASE_URL,
//   timeout: 10000,
//   headers: {
//     "Access-Control-Allow-Origin": "*",
//     "Content-Type": "application/json",
//   },
// });


export const HTTP_LEADERBOARD = axios.create({
  // baseURL: process.env.VUE_APP_LEADERBOARD_BASE_URL,
  // baseURL: "http://159.223.27.42:5000/api",
  // baseURL: "https://161.35.70.77:5000/api",
  baseURL: "https://apileba.grandecaccia.it/api",
  // baseURL: `${window.location.protocol}://${window.location.hostname}:5000/api`,
  // baseURL: `${window.location.protocol}://apileba.grandecaccia.it:5000/api`,
  timeout: 10000,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  //   "Content-Type": "application/json",
  //   // "Authorization": `Bearer ${process.env.VUE_APP_LEADERBOARD_TOKEN}`
  // },
});
