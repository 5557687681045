import { BareActionContext } from "vuex-typex"
import { storeBuilder, RootState } from "@/store/root-state"
import { backendAPI } from "@/api/backendAPI"
import { Player } from '@/types/game'


// state


export class AppState {
    players: Player[] = []
}


const builder = storeBuilder.module<AppState>("app", new AppState())


// getters

const players = builder.read((state: AppState) => state.players, "isConnected")




export const getters = {
    get players(): Player[] {
        return players()
      },
}


// mutations

const setPlayers = builder.commit((state: AppState, players: Player[]) => state.players = players, "setPlayers")

export const mutations = {
    setPlayers: setPlayers,
}


// actions


const fetchPlayers = builder.dispatch((context: BareActionContext<AppState, RootState>) => {
    console.log("fetchPlayers: ")
    return new Promise((resolve, reject) => {
        backendAPI.getPlayers()
        .then(data => {
            mutations.setPlayers(data)
            resolve()
        })
        .catch(error => {
            console.log("FOO", error)
            reject(error)
        })

    })

}, "fetchPlayers")




export const actions = {
    fetchPlayers: fetchPlayers,
}
